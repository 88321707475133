@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Vithkuqi:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.MuiDataGrid-columnHeaderCheckbox>div {
    visibility: hidden !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.LeadFinderFliter .MuiAccordionSummary-contentGutters {
    margin: 0px !important;
}

.LeadFinderFliter .css-1agn754-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
    height: auto !important;
}

.LeadFinderFliter .css-19q924y-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
    height: auto !important;
}

.LeadFinderFliter .css-1df9pbr-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent;
    min-height: auto !important;
    height: auto !important;
}

.LeadFinderFliter .css-tonnrt-MuiPaper-root-MuiAccordion-root:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.exclude-locations .MuiButtonBase-root {
    justify-content: flex-start;
    min-height: auto !important;
    height: auto !important;
    /* padding: 0px !important; */
    /* margin-top: 8px !important; */
    /* margin-bottom: 5px !important; */
}

.exclude-locations .MuiPaper-root {

    padding: 0px !important;
    margin: 0px !important;
}

.exclude-locations .css-o4b71y-MuiAccordionSummary-content {
    flex-grow: 0;
}

.exclude-radio {
    margin-top: 0px !important;
}

.MuiPaper-root {
    background-color: transparent;

}

.css-tonnrt-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent !important;
    height: 0px !important;
}

.advanced-box .MuiAccordionSummary-content {
    flex-grow: 0;
}

.MuiTooltip-tooltip .custom-tooltip {
    padding: 0px 0px;
}

.css-1t2wbtj-MuiInputBase-root-MuiFilledInput-root.Mui-focused {
    box-shadow: none !important;
    border-color: #DCDEE0 !important;
}

.least-times .css-1iwa6oc-MuiInputBase-root-MuiFilledInput-root.Mui-focused {
    box-shadow: none !important;
    border-color: #DCDEE0 !important;
}

.least-times .css-1iwa6oc-MuiInputBase-root-MuiFilledInput-root {
    box-shadow: none !important;
    border: none;
    border-color: #DCDEE0 !important;
}

.least-times .css-1iwa6oc-MuiInputBase-root-MuiFilledInput-root:hover {
    background-color: transparent;
}

.least-times {
    border: 1px solid #DCDEE0;
    border-radius: 8px;
    position: relative;
}

.round-btn {
    border: none;
    border-radius: 6px !important;
}

.default {
    background-color: #E1E3EC !important;
}

.selectedBg {
    background-color: white !important;
}





.custom-date-picker .react-datepicker__input-container input {
    border: 1px solid #DCDEE0;
    border-radius: 8px;
    padding: 10px 15px;
    width: 100%;
}

.custom-date-picker .react-datepicker__input-container input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.custom-date-picker .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 2px;
    top: 2px;
}



.custom-tab-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.custom-tab-container::-webkit-scrollbar-thumb {
    background-color: #CACACC;
    border-radius: 4px;
}

.custom-tab-container::-webkit-scrollbar-thumb:hover {
    background-color: #A9A9A9;
    /* You can change the hover color if needed */
}

.spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   border: 9px solid #474bff;
   animation: spinner-bulqg1 0.8s infinite linear alternate,
        spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}
